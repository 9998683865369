import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  buttonText: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Cinzel',
    fontWeight: 400,
    fontSize: '20px',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    display: 'block',
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  closeIcon: {
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    marginRight: theme.spacing(8),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
  },
}));

interface Props {
  className?: string;
  onClose: Function;
  pages: PagesProps;
}

const SidebarNav = ({
  pages,
  onClose,
  className,
  ...rest
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={() => onClose()}>
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component="a" href="/" className={classes.buttonText}>
          {t('sidebarHomePage')}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          component="a"
          href="/memberships"
          className={classes.buttonText}
        >
          {t('navMemberships')}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component="a" href="/about" className={classes.buttonText}>
          {t('navAbout')}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component="a" href="/contact" className={classes.buttonText}>
          {t('navContact')}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component="a" href="/blog" className={classes.buttonText}>
          {t('navBlog')}
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button component="a" href="/event" className={classes.buttonText}>
          {t('navEvent')}
        </Button>
      </ListItem>
    </List>
  );
};

export default SidebarNav;
