import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Divider } from "@material-ui/core";
import { Topbar, Footer, Sidebar } from "./components";
import Helmet from "react-helmet";
import favicon from "../../assets/images/favicon.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { navigate } from "gatsby";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  children: React.ReactNode;
  themeToggler: Function;
  themeMode: string;
}

const Main = ({ children, themeToggler, themeMode }: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleMembershipsScroll = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    href: string
  ) => {
    event.preventDefault();

    const [pathname, hash] = href.split("#");

    if (window.location.pathname === pathname) {
      const membershipsSection = document.getElementById(hash);
      if (membershipsSection) {
        membershipsSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      await navigate(href);
    }
  };

  const pages = {
    info: {
      title: "Company Info",
      id: "info",
      children: {
        workspaces: {
          groupTitle: t("footerWorkspaceTitle"),
          pages: [
            {
              title: t("footerWorkspaceTitlePrivate"),
              href: "/memberships/#memberships",
              onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
                handleMembershipsScroll(e, "/memberships/#memberships"),
            },
            {
              title: t("footerWorkspaceTitleCoworking"),
              href: "/memberships/#memberships",
              onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
                handleMembershipsScroll(e, "/memberships/#memberships"),
            },
            {
              title: t("footerWorkspaceTitleVirtual"),
              href: "/memberships/#memberships",
              onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
                handleMembershipsScroll(e, "/memberships/#memberships"),
            },
            {
              title: t("footerWorkspaceTitleEvents"),
              href: "/memberships/#memberships",
              onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
                handleMembershipsScroll(e, "/memberships/#memberships"),
            },
          ],
        },
        company: {
          groupTitle: t("footerOurCompanyTitle"),
          pages: [
            {
              title: t("footerOurCompanyHome"),
              href: "/",
              onClick: "scrollToTop",
            },
            // {
            //   title: t('footerOurCompanyBlog'),
            //   href: '/blog',
            //   onClick: 'scrollToTop',
            // },
            {
              title: t("footerOurCompanyMemberships"),
              href: "/memberships",
              onClick: "scrollToTop",
            },
            {
              title: t("footerOurCompanyAbout"),
              href: "/about",
              onClick: "scrollToTop",
            },
            {
              title: t("footerOurCompanyContact"),
              href: "/contact",
              onClick: "scrollToTop",
            },
          ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const handleSidebarOpen = (): void => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = (): void => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Helmet>
        <link rel="icon" type="image/ico" href={favicon} />
      </Helmet>
      <Topbar
        onSidebarOpen={handleSidebarOpen}
        pages={pages}
        themeMode={themeMode}
        themeToggler={themeToggler}
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main style={{ flex: 1 }}>
        <Divider />
        {children}
      </main>
      <Footer pages={pages} />
    </div>
  );
};

export default Main;
