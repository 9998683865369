import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Image } from "components/atoms";
import footerlogo from "./footerlogo.webp";
import { useTranslation } from "gatsby-plugin-react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 120,
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
    display: "block",
    margin: "0 auto",
    width: "124px",
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: "rgba(255,255,255,.6)",
    "&:hover": {
      background: "transparent",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
    color: theme.palette.primary.contrastText,
  },
  menuListContainer: {
    padding: "0 !important",
  },
  menu: {
    display: "flex",
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  menuItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(3 / 2),
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    color: theme.palette.secondary.main,
  },
  divider: {
    width: "100%",
  },
  navLink: {
    color: theme.palette.primary.contrastText,
  },
}));

interface Props {
  className?: string;
  pages: PagesProps;
}

const Footer = ({ pages, className, ...rest }: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const info = pages.info;

  const MenuGroup = ({ item }: MenuGroupProps): JSX.Element => (
    <List disablePadding className={classes.menuItem}>
      <ListItem disableGutters className={classes.menuGroupItem}>
        <Typography variant="h6" className={classes.menuGroupTitle}>
          {item.groupTitle}
        </Typography>
      </ListItem>
      {item.pages.map((page, i) => (
        <ListItem disableGutters key={i} className={classes.menuGroupItem}>
          <Typography
            variant="body1"
            component={"a"}
            href={page.href}
            className={clsx(classes.navLink, "submenu-item")}
            onClick={page.onClick ? (e) => page.onClick(e) : undefined}
          >
            {page.title}
          </Typography>
        </ListItem>
      ))}
    </List>
  );

  const GetInTouch = (): JSX.Element => {
    return (
      <div>
        <div className={classes.menu}>
          <List>
            <ListItem disableGutters>
              <Typography align="left" variant="h6" color="secondary">
                {t("footerGetInTouchTitle")}
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <Typography variant="body1" align="left">
                <i className={clsx("fas fa-phone-alt")} />
                (852) 2163 7688
              </Typography>
            </ListItem>
            <ListItem disableGutters>
              <Typography variant="body1" align="left">
                <i className={clsx("fa fa-map-marker")} />
                {t("footerGetInTouchAddress")
                  .split("\n")
                  .map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
              </Typography>
            </ListItem>
          </List>
        </div>
      </div>
    );
  };

  const WorkspaceSolutions = (): JSX.Element => {
    const { workspaces } = info.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={workspaces} />
        </div>
      </div>
    );
  };

  const OurCompany = (): JSX.Element => {
    const { company } = info.children;
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={company} />
        </div>
      </div>
    );
  };

  const SocialMedia = (): JSX.Element => {
    return (
      <div className={classes.menu}>
        <List>
          <ListItem disableGutters>
            <Typography variant="h6" color="secondary">
              {t("footerFollowUsTitle")}
            </Typography>
          </ListItem>
          <ListItem disableGutters>
            <IconButton
              href="https://www.facebook.com/thecenterspace.co"
              className={classes.socialIcon}
              target="_blank"
            >
              <FacebookIcon className={classes.icon} />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/thecenterspace.co/"
              className={classes.socialIcon}
              target="_blank"
            >
              <InstagramIcon className={classes.icon} />
            </IconButton>
            <IconButton
              href="https://www.linkedin.com/company/the-center-space/"
              className={classes.socialIcon}
              target="_blank"
            >
              <LinkedInIcon className={classes.icon} />
            </IconButton>
          </ListItem>
        </List>
      </div>
    );
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={2}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div>
                  <a href="/" title="thecenterspace">
                    <Image src={footerlogo} alt="thecenterspace" lazy={false} />
                  </a>
                </div>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={10} className={classes.menuListContainer}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={3}>
                <GetInTouch />
              </Grid>
              <Grid item xs={12} md={4}>
                <WorkspaceSolutions />
              </Grid>
              <Grid item xs={12} md={3}>
                <OurCompany />
              </Grid>
              <Grid item xs={12} md={2}>
                <SocialMedia />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
