import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby-plugin-react-i18next";
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  IconButton,
  Button,
  Menu,
  MenuItem,
  NoSsr,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Image } from "components/atoms";
import navlogo from "./navlogo.webp";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import globeIcon from "./globeSolid.svg";

const useStyles = makeStyles((theme) => ({
  bg: {
    background: theme.palette.primary.main,
  },
  buttonNav: {
    textTransform: "none",
    color: theme.palette.primary.contrastText,
    width: "85px",
  },
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8),
    },
  },
  listItem: {
    cursor: "pointer",
    "&:hover > .menu-item, &:hover svg": {
      color: theme.palette.primary.dark,
    },
    "&.menu-item--no-dropdown": {
      paddingRight: 0,
    },
  },
  listItemButton: {
    textTransform: "none",
    whiteSpace: "nowrap",
    fontWeight: 500,
    fontSize: "14px",
  },
  rightNavButton: {
    textTransform: "none",
    fontWeight: 500,
    fontSize: "14px",
    right: -5,
  },
  iconButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  navlogo: {
    width: "139px",
    height: "38.75px",
    marginTop: "7px",
  },
  langIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: "1.25rem",
  },
}));

interface Props {
  className?: string;
  onSidebarOpen: Function;
  pages: PagesProps;
  themeMode: string;
  themeToggler: Function;
}

const Topbar = ({
  themeMode,
  themeToggler,
  onSidebarOpen,
  pages,
  className,
  ...rest
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { changeLanguage } = useI18next();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeLanguage = async (event) => {
    changeLanguage(event.target.getAttribute("value"));
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.bg}>
      <Toolbar disableGutters className={classes.toolbar} {...rest}>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <div>
          <Link
            to="/"
            title="thecenterspace"
            style={{
              textDecoration: `none`,
            }}
          >
            <Image
              src={navlogo}
              alt="thecenterspace"
              lazy={false}
              className={classes.navlogo}
            />
          </Link>
        </div>
        <Hidden mdUp>
          <div className={classes.flexGrow} />
          <List disablePadding className={classes.navigationContainer}>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Button
                variant="contained"
                color="secondary"
                href="https://portal.thecenterspace.co/"
                className={classes.rightNavButton}
              >
                {t("navLogin")}
              </Button>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
               <Image
                aria-controls="language-menu"
                aria-haspopup="true"
                id="outlined-select-salutation"
                onClick={handleClick}
                src={globeIcon}
                alt="globe"
                style={{ height: 20 }}
              />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                id="simple-menu"
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleChangeLanguage}
                  value="en"
                  style={{ fontFamily: "Roboto" }}
                >
                  Eng
                </MenuItem>
                <MenuItem
                  onClick={handleChangeLanguage}
                  value="zh"
                  style={{ fontFamily: "Roboto" }}
                >
                  中
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Hidden>
        <Hidden smDown>
          <div className={classes.flexGrow} />
          <List disablePadding className={classes.navigationContainer}>
          <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Link
                to="/event"
                title="thecenterspace"
                style={{
                  textDecoration: `none`,
                }}
              >
                <Button className={classes.buttonNav}>{t("navEvent")}</Button>
              </Link>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Link
                to="/blog"
                title="thecenterspace"
                style={{
                  textDecoration: `none`,
                }}
              >
                <Button className={classes.buttonNav}>{t("navBlog")}</Button>
              </Link>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Link
                to="/memberships"
                title="thecenterspace"
                style={{
                  textDecoration: `none`,
                }}
              >
                <Button className={classes.buttonNav}>
                  {t("navMemberships")}
                </Button>
              </Link>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Link
                to="/about"
                title="thecenterspace"
                style={{
                  textDecoration: `none`,
                }}
              >
                <Button className={classes.buttonNav}>{t("navAbout")}</Button>
              </Link>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Link
                to="/contact"
                title="thecenterspace"
                style={{
                  textDecoration: `none`,
                }}
              >
                <Button className={classes.buttonNav}>{t("navContact")}</Button>
              </Link>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Button
                variant="contained"
                color="secondary"
                href="https://portal.thecenterspace.co/"
                className={classes.listItemButton}
              >
                {t("navLogin")}
              </Button>
            </ListItem>
            <ListItem
              className={clsx(classes.listItem, "menu-item--no-dropdown")}
            >
              <Image
                aria-controls="language-menu"
                aria-haspopup="true"
                id="outlined-select-salutation"
                onClick={handleClick}
                src={globeIcon}
                alt="globe"
                style={{ height: 20 }}
              />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                id="simple-menu"
                onClose={handleClose}
              >
                <MenuItem
                  onClick={handleChangeLanguage}
                  value="en"
                  style={{ fontFamily: "Roboto" }}
                >
                  Eng
                </MenuItem>
                <MenuItem
                  onClick={handleChangeLanguage}
                  value="zh"
                  style={{ fontFamily: "Roboto" }}
                >
                  中
                </MenuItem>
              </Menu>
            </ListItem>
          </List>
        </Hidden>
      </Toolbar>
    </div>
  );
};

export default Topbar;
